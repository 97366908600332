import {
    getProviderLogo,
    getContentDetailSource,
    cloudinaryCarousalUrl,
    safeNavigation,
    isMobile,
    queryStringToObject,
    getFormattedURLValue,
    getFormattedContentTitle,
    providerImage,
    isUserloggedIn,
    showLoginScreen,
    getAnalyticsSource,
    redirectToMangeApp,
    specialRailUseCases,
    setRailTypeForHome,
    isHomePIRelatedRail,
    deeplinkToPIPage,
    getBrowseByKeyFromUrl,
    getSeeAllUrl,
    getUrlLength,
    getPiPageUrlContentType,
    isLiveSquareCards,
    isContentLive,
    isLoginBeforeSubscribe,
    getSearchRefUseCaseId,
    getSquareProviderLogo,
    triggerChannelRailEvents,
} from "@utils/common";
import {
    LAYOUT_TYPE,
    CONTENTTYPE,
    SECTION_SOURCE,
    MINI_SUBSCRIPTION,
    LOCALSTORAGE,
    SCREEN_NAME,
    MID_SCROLL_ARRAY,
    REVERSE_FORMATTED_CONTENT_TYPE,
    LOGIN_BEFORE_SUBSCRIBE,
    BEST_OF_APPS
} from "@utils/constants";
import { URL } from "@constants/routeConstants";
import imagePlaceholderPortrait from "@assets/images/image-placeholder-portrait.png";
import imagePlaceholderLandscape from "@assets/images/image-placeholder-landscape.png";
import imagePlaceholderAppRail from "@assets/images/app-icon-place.svg";
import imagePlaceholderRailLogoLandscape from "@assets/images/image-placeholder-rail-logo-landscape.png";
import imagePlaceholderRailLogoPortrait from "@assets/images/image-placeholder-rail-logo-potrait.png";
import imagePlaceholderForBrowseByGenre from "@assets/images/browse-by-genre-background.png";
import imagePlaceholderForBrowseByGLanguage from "@assets/images/browse-by-lang-background.png";
import isEmpty from "lodash/isEmpty";
import { BROWSE_TYPE } from "@containers/BrowseByDetail/APIs/constants";
import { PROVIDER_NAME } from "@constants/playerConstant";
import { LISTING_ITEM_CLASS } from './constant';
import { closePopup, openPopup } from "@common/Modal/action";
import { openLoginPopup } from "@containers/Login/APIs/actions";
import store from "@src/store";
import { checkCurrentSubscription } from "@containers/Subscription/APIs/subscriptionCommon";
import { get } from "lodash";
import { getKey } from "@utils/storage";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import { gamezopAddWatchlist } from "@containers/PlayerWeb/APIs/actions";
import { SUBSCRIPTION_STATUS } from "@containers/Subscription/APIs/constant";
import FIREBASE from "@utils/constants/firebase";
import { isLivePlayable } from "@containers/PIDetail/PIDetailCommon";


export const getImage = (view) => {
    if (view === LAYOUT_TYPE.LANDSCAPE) {
        return imagePlaceholderLandscape;
    } else if (
        [LAYOUT_TYPE.PORTRAIT, LAYOUT_TYPE.TOP_PORTRAIT].includes(view)
    ) {
        return imagePlaceholderPortrait;
    } else if (view === LAYOUT_TYPE.CIRCULAR) {
        return imagePlaceholderAppRail;
    } else {
        return imagePlaceholderLandscape;
    }
};

export const getRailLogoImagePlaceholder = (view) => {
    if (view === LAYOUT_TYPE.LANDSCAPE) {
        return imagePlaceholderRailLogoLandscape;
    } else if (
        [LAYOUT_TYPE.PORTRAIT, LAYOUT_TYPE.TOP_PORTRAIT].includes(view)
    ) {
        return imagePlaceholderRailLogoPortrait;
    } else {
        return imagePlaceholderRailLogoLandscape;
    }
};


export const getLogoImageUrl = (item, view, sectionSource, hoveredData) => {
    let logoWidth = "auto",
        logoHeight = !!isMobile.any() ? 24 : 52;
    let imageUrl = "";
    let providerLogo = getProviderLogo();

    if (providerLogo && Object.entries(providerLogo).length !== 0) {
        let url = getSquareProviderLogo(item?.provider);
        logoWidth = isMobile.any() ? 40 : 56;
        logoHeight = isMobile.any() ? 40 : 56;
        imageUrl = url
            ? `${cloudinaryCarousalUrl(
                "",
                "",
                logoWidth,
                logoHeight,
            )}${url}`
            : "";
    }
    return imageUrl;
};

export const getPathNameOnClick = (props) => {
    let {
        sectionSource,
        title,
        item,
        location: { pathname },
        routeName,
        pageType,
        pathUpdated,
        newRailTitle,
        source,
        taRecommendedRail,
        railPosition,
        history,
        contentPosition,
        configType,
        sectionType,
        contentSectionSource,
        refUseCase,
        railId,
        railItem,
    } = props;
    let path = pathname;
    let railTitle = title;
    let updatedSource = sectionSource;

    if (pageType === "seeAll") {
        path = routeName;
    } else if (pageType === "browseBy") {
        path = pathUpdated;
        updatedSource = source;
    } else if (pageType === "search") {
        railTitle = railTitle || "";
    } else if (pageType === "searchLanguage" || pageType === "searchGenre") {
        railTitle = newRailTitle;
    }

    let routeUrl = pathname?.split("/");
    let breadCrumbSource = pathname && routeUrl[1];
    breadCrumbSource = isEmpty(breadCrumbSource) ? URL.HOME : breadCrumbSource;

    let detailParam = {
        item, taRecommendedRail, routeUrl, breadCrumbSource,
        railTitle, railPosition, pathname, updatedSource, contentPosition, configType,
        sectionType, contentSectionSource, refUseCase, railId, history,
    },
        browseByParam = { sectionSource, item, railTitle, path, pageType, breadCrumbSource, refUseCase, railPosition, railId };

    //Checks if the rail is configured on partner, BBL, BBG, Categories or Search page then call specialRailUseCases otherwise set the rail type for home page
    let isHomePIRail = isHomePIRelatedRail(routeUrl),
        isMixedRail = railItem?.mixedRail;
    //Added check for mix rail to get individual section source of content and not rail section source
    sectionSource = isMixedRail ? item.sectionSource : sectionSource;
    isHomePIRail ? setRailTypeForHome(sectionSource) : specialRailUseCases(routeUrl, sectionSource);

    if ([SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.GENRE_RAIL_FOR_GAMES].includes(sectionSource)) {
         return getBrowseByPath(browseByParam);
         
    } else if (item.contentType === CONTENTTYPE.TYPE_SUB_PAGE && sectionSource !== SECTION_SOURCE.CATEGORY) {
        return  getPartnerPagePath(item, breadCrumbSource);
    } else if (item.contentType !== CONTENTTYPE.TYPE_SUB_PAGE) {
        return getDetailPagePath(detailParam);
    } else if (item.contentType === CONTENTTYPE.TYPE_SUB_PAGE && sectionSource === SECTION_SOURCE.CATEGORY) {
        return getCategoriesPagePath(item);
    }
};

export const getBrowseByPlaceholder = (sectionSourceName) => {
    if (sectionSourceName === SECTION_SOURCE.LANGUAGE) {
        return imagePlaceholderForBrowseByGLanguage;
    } else {
        return imagePlaceholderForBrowseByGenre;
    }
};

export const getBrowseByPath = (browseByParam) => {
    let { sectionSource, item, railTitle, path, pageType, breadCrumbSource, refUseCase, railId } = browseByParam;
    let browseType = sectionSource === BROWSE_TYPE.LANGUAGE ? URL.BROWSE_BY_LANGUAGE : URL.BROWSE_BY_GENRE;
    let pathname = "";
    if (sectionSource === SECTION_SOURCE.GENRE_RAIL_FOR_GAMES) {
        pathname = `/${URL.GAMES}/${browseType}/${getFormattedURLValue(item?.title)}/${railId}`;
    } else {
        pathname = [URL.MOVIES, URL.TV_Shows, URL.SPORTS].includes(breadCrumbSource)
            ? `/${breadCrumbSource}/${browseType}/${getFormattedURLValue(item?.title)}`
            : `/${browseType}/${getFormattedURLValue(item?.title)}`;
    }

    return {
        pathname,
        state: {
            railTitle: railTitle,
            pathUpdated: path,
            pageType: pageType,
            bannerImg: item.backgroundImage,
            bannerLogoImg: item.image,
            breadCrumbSource: breadCrumbSource,
            title: item?.title,
            sectionSource,
            refUseCase,
        },
    };
};

export const getPartnerPagePath = (item, breadCrumbSource) => {
    let provider = getProviderForUrl(item.provider);
    return {
        pathname: `/${URL.PARTNER}/${getFormattedURLValue(provider)}/${item.partnerId}`,
        state: { partnerName: item.provider },
        search: `?breadCrumbSource=${breadCrumbSource}`,
    };
}

export const getDetailPagePath = (detailParam) => {
    let { item, taRecommendedRail, routeUrl, breadCrumbSource,
        railTitle, railPosition, pathname, updatedSource, contentPosition, configType,
        sectionType, contentSectionSource, refUseCase, railId, history } = detailParam;

    let updatedContentType = item?.contentType;
    let updatedId = item?.id ? item?.id : item?.contentId;
    const { location: { state } } = history

    if (taRecommendedRail) {
        updatedContentType = item.seriescontentType
            ? item.seriescontentType
            : updatedContentType;
        updatedId = item.seriesvrId ? item.seriesvrId : updatedId;
    }

    let search = queryStringToObject(location.search);
    let sourcePath = routeUrl.includes("breadCrumbSource")
    let pathValid = "";
    if (sourcePath === false) {
        pathValid = history.location;
    }

    let source = getBreadCrumbForPi(breadCrumbSource, routeUrl);
    let stateObj = {
        railTitle,
        railPosition,
        configType,
        sectionType,
        refUseCase,
        contentSectionSource,
        sectionSource: updatedSource,
        origin: location?.state?.prevPath,
        conPosition: contentPosition + 1,
        taRecommendedRail: taRecommendedRail,
        source: state?.source === MIXPANEL.VALUE.SEARCH_SUGGESTION ? MIXPANEL.VALUE.SEARCH_SUGGESTION : getContentDetailSource(
            pathname,
            updatedContentType,
            item.contractName
        ),
        selectedContentSectionSource: item?.sectionSource,
        genre: item?.genre?.toString(),
        language: item?.language?.toString(),
        contentId: updatedId,
        provider: item?.provider,
        providerContentId: item?.providerContentId,
        //this is section source of selected content
    };

    // handling of direct player on live rail item click
    let isDistroTV = item?.provider?.toLowerCase() === PROVIDER_NAME.DISTRO_TV;
    let sectionSourceToCheck = updatedSource === SECTION_SOURCE.SEARCH ? item?.sectionSource : updatedSource;
    let cType = item?.contentType?.toUpperCase() === CONTENTTYPE.CUSTOM_LIVE_DETAIL
        ? REVERSE_FORMATTED_CONTENT_TYPE[CONTENTTYPE.TYPE_LIVE]
        : getPiPageUrlContentType(updatedContentType);
    let urlLength = getUrlLength(`/${URL.DETAIL}/${cType}/${getFormattedURLValue(item.title)}/${updatedId}`);
    let pathForContentPi = `/${URL.DETAIL}/${cType}/${getFormattedContentTitle(item.title, urlLength)}/${updatedId}${isDistroTV ? "/"+item?.provider:""}`;
    let checkLivePlayability = isUserloggedIn() && isContentLive(item?.contentType) && isLivePlayable(item?.id?.toString());

    if (checkLivePlayability) {
        return {
            pathname: `/${URL.PLAYER}/${cType}/${item.id}`,
            state: { ...stateObj, ...{ isLiveAutoPlay: true, pathForContentPi, railId, liveProvider: item?.provider } },
        }
    }
    else {
        return {
            pathname: pathForContentPi,
            state: {
                ...stateObj, ...{
                    title: item.title,
                    setPathIs: pathValid,
                    railId: railId,
                }
            },
            search: `?breadCrumbSource=${cType}`,
        };
    }
};


export const getBreadCrumbForPi = (source, routeUrl) => {
    let breadCrumbSource = source;
    let browseByValue = getBrowseByKeyFromUrl(routeUrl);
    return breadCrumbSource = [URL.BROWSE_BY_GENRE, URL.BROWSE_BY_LANGUAGE].includes(breadCrumbSource)
        ? ((browseByValue === URL.BROWSE_BY_LANGUAGE) ? URL.LANGUAGE : (browseByValue === URL.BROWSE_BY_GENRE) ? BROWSE_TYPE.GENRE.toLowerCase() : breadCrumbSource)
        : breadCrumbSource;
}

export const getCategoriesPagePath = (item) => {
    return {
        pathname: `/${URL.CATEGORIES}/${getFormattedURLValue(item.provider)}`,
    };
}

export const itemDetailVisibility = (sectionSource, props) => {
    const { isToolTipRequired, isHover, isBackgroundRailProviderEmpty, item, view } = props;

    let isLogoVisible = [];

    if (SECTION_SOURCE.BACKGROUND_BANNER_RAIL === sectionSource) {
        isLogoVisible = isBackgroundRailProviderEmpty ? [] : [SECTION_SOURCE.BACKGROUND_BANNER_RAIL];
    }

    // if (sectionSource === SECTION_SOURCE.PROVIDER && pageType !== "seeAll") {
    //     return false;
    // }

    if (item?.provider?.toLowerCase() === PROVIDER_NAME.DISTRO_TV && sectionSource === SECTION_SOURCE.BINGE_CHANNEL && [LAYOUT_TYPE.LANDSCAPE, LAYOUT_TYPE.PORTRAIT].includes(view)) {
        return true;
    }

    if(isToolTipRequired && !isHover && ![SECTION_SOURCE.GENRE, SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE, SECTION_SOURCE.PROVIDER_BROWSE_APPS, SECTION_SOURCE.BACKGROUND_BANNER_RAIL, SECTION_SOURCE.SEASONS,SECTION_SOURCE.BROWSE_BY_CHANNEL].includes(sectionSource)){
        return true;
    }

    let sectionSourceListToHide = [
        SECTION_SOURCE.SEASONS,
        SECTION_SOURCE.LANGUAGE,
        SECTION_SOURCE.GENRE,
        SECTION_SOURCE.CATEGORY,
        SECTION_SOURCE.BINGE_CHANNEL,
        SECTION_SOURCE.BINGE_DARSHAN_LIVE,
        SECTION_SOURCE.GENRE_RAIL_FOR_GAMES,
        SECTION_SOURCE.MID_BANNER_GAMES,
        SECTION_SOURCE.PROVIDER_BROWSE_APPS,
        SECTION_SOURCE.BROWSE_BY_CHANNEL,
        ...MID_SCROLL_ARRAY,
        ...isLogoVisible];

    return !sectionSourceListToHide.includes(sectionSource);
};

export const getContentImageUrl = (sectionSource, view, item, taRecommendedRail, isBBA) => {
    if (isBBA) {
        return providerImage(item.provider, view, isBBA);
    }else {
        if (taRecommendedRail) {
            return item.seriesimage ? item.seriesimage : item.image;
        }
        else if (sectionSource === SECTION_SOURCE.BINGE_CHANNEL) {
            return item?.newImageUrl || item?.image;
        } else if (item?.isChannelDetailPage){
            return view == LAYOUT_TYPE.LANDSCAPE ? item?.imageLandscape : item?.imagePortrait
        }
        else {
            return item.image;
        }
    }
};

export const isTopTenRail = (sectionSource, view, isTopTen = false) => {
    if (isTopTen) {
        return (sectionSource === SECTION_SOURCE.BINGE_TOP_10_RAIL && view === LAYOUT_TYPE.TOP_PORTRAIT)
    }
    return (
        sectionSource === SECTION_SOURCE.BINGE_TOP_10_RAIL &&
        (view === LAYOUT_TYPE.TOP_PORTRAIT || view === LAYOUT_TYPE.SQUARE)
    );
};

export const isTitleRail = (sectionSource, view, isBackgroundRailProviderEmpty) => {
    return ( (sectionSource === SECTION_SOURCE.TITLE_RAIL || sectionSource === SECTION_SOURCE.EPISODE_RAIL || (SECTION_SOURCE.BACKGROUND_BANNER_RAIL === sectionSource) ) && view === LAYOUT_TYPE.LANDSCAPE )
}

export const isSearchWebShorts = (sectionSource, item) => {
    return sectionSource === SECTION_SOURCE.SEARCH && item.contentType === CONTENTTYPE.TYPE_WEB_SHORTS;
};

export const getClassName = (sectionSource, railItem, history, item, contentPosition) => {
    const { location: { state } } = history
    let classNameObj = LISTING_ITEM_CLASS.find(i => i.sectionSource === sectionSource);
    let className = '';
    let isBingeTopTengames = sectionSource === SECTION_SOURCE.BINGE_TOP_10_RAIL && railItem?.layoutType === LAYOUT_TYPE.SQUARE
    if (!isEmpty(classNameObj)) {
        className = classNameObj.className
    } else if (isBingeTopTengames) {
        if (contentPosition + 1 > 9) {
            className = 'listing-top-ten-games listing-double-number'
        } else {
            className = 'listing-top-ten-games'
        }

    } else if (railItem?.layoutType === LAYOUT_TYPE.SQUARE || state?.sectionSource === SECTION_SOURCE.GENRE_RAIL_FOR_GAMES) {
        className = 'listing-square'
    } else if (sectionSource === SECTION_SOURCE.MID_BANNER_GAMES) {
        className = 'listing-mid-banner-games'
    } else if (MID_SCROLL_ARRAY.includes(sectionSource)) {
        className = 'listing-mid-scroll-banner'
    } else if (sectionSource === SECTION_SOURCE.SEARCH && item?.provider?.toLowerCase() === PROVIDER_NAME.GAMEZOP) {
        className = 'listing-search-games'
    }
    return className
};

export const secondsToHms = (data) => {
    let d = data;
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
    return hDisplay + mDisplay;
}

export const getCardImage = (sectionSource, view, item, taRecommendedRail, hoveredData) => {
    let genreWidth = isMobile.any() ? 120 : 180;
    let genreHeight = isMobile.any() ? 60 : 100;
    let isBBA = sectionSource === SECTION_SOURCE.PROVIDER ? true : false
    if (sectionSource === SECTION_SOURCE.GENRE) {
        return `${cloudinaryCarousalUrl(
            "",
            "",
            genreWidth,
            genreHeight,
            isMobile.any(),
        )}${getContentImageUrl(sectionSource, view, item, taRecommendedRail)}`;
    }
    else if (sectionSource === SECTION_SOURCE.GENRE_RAIL_FOR_GAMES || isLiveSquareCards(sectionSource,view)) {
        return `${cloudinaryCarousalUrl(
            "",
            "",
            180,
            180,
            isMobile.any(),
        )}${getContentImageUrl(sectionSource, view, item, taRecommendedRail)}`;
    }
    else if (MID_SCROLL_ARRAY.includes(sectionSource)) {
        return `${cloudinaryCarousalUrl(
            "",
            "",
            420,
            336,
            isMobile.any(),
        )}${getContentImageUrl(sectionSource, view, item, taRecommendedRail)}`;
    } else if (sectionSource === SECTION_SOURCE.BROWSE_BY_CHANNEL && !item?.isChannelDetailPage) {
        return `${cloudinaryCarousalUrl(
            "",
            "",
            240,
            240,
            isMobile.any(),
        )}${getContentImageUrl(sectionSource, view, item, taRecommendedRail)}`;
    }
    else {
        return `${cloudinaryCarousalUrl(
            hoveredData.hovered,
            view,
            "",
            "",
            !!isMobile.any(),
        )}${getContentImageUrl(sectionSource, view, item, taRecommendedRail, isBBA)}`;
    }

};

export const handleContentClick = (e, props) => {
    const {
        item,
        sectionSource,
        sectionType,
        contentPosition,
        title,
        railPosition,
        isPartnerPage,
        onClickHandler,
        onPlayContent,
        configType,
        location,
    } = props;

    const specialSectionSource = [SECTION_SOURCE.PROVIDER, SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.GENRE_RAIL_FOR_GAMES, SECTION_SOURCE.PROVIDER_BROWSE_APPS, SECTION_SOURCE.CATEGORY, SECTION_SOURCE.BROWSE_BY_CHANNEL].includes(sectionSource)
    const loginBeforeSource = MID_SCROLL_ARRAY.includes(sectionSource) ? LOGIN_BEFORE_SUBSCRIBE.MID_SCROLL : LOGIN_BEFORE_SUBSCRIBE.CONTENT_CARD_CLICK

    if (isLoginBeforeSubscribe(loginBeforeSource) && (!specialSectionSource || (props?.isBBAContentClick && [SECTION_SOURCE.PROVIDER_BROWSE_APPS].includes(sectionSource)))){
        const contentData = { event :e, props:props }
        showLoginScreen(openPopup, closePopup, openLoginPopup, item, MINI_SUBSCRIPTION.LOGIN, MIXPANEL.VALUE.CONTENT_PLAYBACK, loginBeforeSource, contentData);
        return
    }

    let isExplore = props?.isExplore ? true : false ;

    let data = {
        sectionSource,
        sectionType,
        contentPosition,
        title,
        railPosition,
        isPartnerPage,
        configType,
        location,
        isExplore,
    }
    let gamezopProvider = item?.provider?.toLowerCase() === PROVIDER_NAME.GAMEZOP;
    e.stopPropagation();
    if (!props.select) {
        const isEpisodeContent = sectionSource === SECTION_SOURCE.SEASONS;
        if (isEpisodeContent) {
            onPlayContent({
                ...item,
                type: 'seasonsType',
                isEpisodeContent: true
            })
        } else if (gamezopProvider) {
            gameClick(item, data);
        } else if (sectionSource === SECTION_SOURCE.MID_BANNER_GAMES && !gamezopProvider) {
            safeNavigation(props.history, URL.GAMES);
        } else if (MID_SCROLL_ARRAY.includes(sectionSource)) {
            midBannerRedirection(item, props);
        } else if (sectionSource == SECTION_SOURCE.BROWSE_BY_CHANNEL && !item?.isChannelDetailPage){
            redirectToChannelPage(props)
        } 
        else {
            safeNavigation(props.history, getPathNameOnClick(props));
        }
    }
    onClickHandler && onClickHandler(e, item, data);
}

export const handleContentPath = (props) => {
    const { item, sectionSource} = props;

    let gamezopProvider = item?.provider?.toLowerCase() === PROVIDER_NAME.GAMEZOP;

    // Ensure `props.select` is handled safely
    if (!props?.select) {
        const isEpisodeContent = sectionSource === SECTION_SOURCE.SEASONS;

        if (isEpisodeContent) {
           return false; // Indicate that the link should not be rendered
             
        } else if (gamezopProvider) {
            // Handle GAMEZOP provider logic if needed
            // gameClick(item, data);
            return null; // If no action is needed, return null explicitly
        } else if (sectionSource === SECTION_SOURCE.MID_BANNER_GAMES && !gamezopProvider) {
            return URL.GAMES; // Ensure URL.GAMES is defined
        } else if (MID_SCROLL_ARRAY.includes(sectionSource)) {
            let redirectionPath = getMidBannerRedirectionPath(item?.screenName, props , true);
            return redirectionPath;
        } else if (sectionSource == SECTION_SOURCE.BROWSE_BY_CHANNEL && !item?.isChannelDetailPage) {
            return redirectToChannelPage(props, true)
        } else {
            return getPathNameOnly(props);
        }
    }
};


export const getPathNameOnly = (props) => {
    const {
        sectionSource,
        title,
        item,
        location: { pathname } = {}, // Safe fallback for location
        routeName,
        pageType,
        pathUpdated,
        newRailTitle,
        source,
        taRecommendedRail,
        railPosition,
        history,
        contentPosition,
        configType,
        sectionType,
        contentSectionSource,
        refUseCase,
        railId,
        railItem,
    } = props;

    // Default values
    let path = pathname ;
    let railTitle = title || "";
    let updatedSource = sectionSource || "";
    // Update path and source based on pageType
    if (pageType === "seeAll") {
        path = routeName || path;
    } else if (pageType === "browseBy") {
        path = pathUpdated || path;
        updatedSource = source || updatedSource;
    } else if (pageType === "searchLanguage" || pageType === "searchGenre") {
        railTitle = newRailTitle || railTitle;
    }

    // Safely split pathname to compute `routeUrl`
    const routeUrl = pathname ? pathname.split("/") : [];
    const breadCrumbSource = routeUrl[1] || URL.HOME;

    // Construct parameters for path functions
    const detailParam = {
        item,
        taRecommendedRail,
        routeUrl,
        breadCrumbSource,
        railTitle,
        railPosition,
        pathname: path,
        updatedSource,
        contentPosition,
        configType,
        sectionType,
        contentSectionSource,
        refUseCase,
        railId,
        history,
    };

    const browseByParam = {
        sectionSource,
        item,
        railTitle,
        path,
        pageType,
        breadCrumbSource,
        refUseCase,
        railPosition,
        railId,
    };

    // Handle special cases based on sectionSource and contentType
    try {
        if ([SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.GENRE_RAIL_FOR_GAMES].includes(sectionSource)) {
            const res = getBrowseByPath(browseByParam);
            return res?.pathname;
        }

        if (item?.contentType === CONTENTTYPE.TYPE_SUB_PAGE && sectionSource !== SECTION_SOURCE.CATEGORY) {
            const res = getPartnerPagePath(item, breadCrumbSource);
            return res?.pathname;
        }

        if (item?.contentType !== CONTENTTYPE.TYPE_SUB_PAGE) {
            const res = getDetailPagePath(detailParam);
            return res?.pathname;
        }

        if (item?.contentType === CONTENTTYPE.TYPE_SUB_PAGE && sectionSource === SECTION_SOURCE.CATEGORY) {
            const res = getCategoriesPagePath(item);
            return res?.pathname ;
        }
    } catch (error) {
        console.error("Error in path calculation:", error);
    }

    // Default to current pathname if no condition matches
    return path?.split("?")[0];
};



export const gameClick = async (item, data, isPlayNow) => {
    if (isUserloggedIn()) {
        trackMixpanelGameClick(item, data, isPlayNow);
        await store.dispatch(gamezopAddWatchlist(item?.id, item?.contentType, true, true));
        redirectToPlayUrl(item);
    } else {
        await showLoginScreen(openPopup, closePopup, openLoginPopup, item, MINI_SUBSCRIPTION.LOGIN, MIXPANEL.VALUE.GAME);
    }
}

const redirectToPlayUrl = (item) => {
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {});
    let currentSubscriptionStatus = get(currentSubscription, 'subscriptionStatus');
    let isSubscribed = !checkCurrentSubscription(currentSubscription) && currentSubscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE;
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let url = `${isSubscribed ? item?.playUrl : item?.adPlayUrl}?sub=${userInfo?.baId}`
    setTimeout(() => {
        window.open(url, '_blank');
    })
}

export const checkIsGamezopFav = (contentId) => {
    const watchListId = JSON.parse(getKey(LOCALSTORAGE.GAMEZOP_WATCHLIST));
    return watchListId?.includes(parseInt(contentId));
};

const trackMixpanelGameClick = (item, railData, isPlayNow) => {
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {});
    let isUserSubscribed = checkCurrentSubscription(currentSubscription);
    let analyticsPlayNow = {
        [MIXPANEL.PARAMETER.HERO_BANNER_NUMBER]: railData?.contentPosition + 1,
        [MIXPANEL.PARAMETER.HERO_BANNER_ORIENTATION]: MIXPANEL.VALUE.LANDSCAPE,
        [MIXPANEL.PARAMETER.PAGE_NAME]: getAnalyticsSource(railData?.location?.pathname, MIXPANEL),
        [MIXPANEL.PARAMETER.CONTENT_PARENT_TITLE]: item?.title,
        [MIXPANEL.PARAMETER.CONTENT_TITLE]: item?.title,
    }
    let mixpanelData = {
        [MIXPANEL.PARAMETER.PAGE_NAME]: getAnalyticsSource(railData?.location?.pathname, MIXPANEL),
        [MIXPANEL.PARAMETER.RAIL_CATEGORY]: railData?.sectionType,
        [MIXPANEL.PARAMETER.SOURCE]: getAnalyticsSource(railData?.location?.pathname),
        [MIXPANEL.PARAMETER.GAME_POSITION]: railData?.contentPosition + 1,
        [MIXPANEL.PARAMETER.RAIL_TITLE]: railData?.title,
        [MIXPANEL.PARAMETER.RAIL_POSITION]: railData?.railPosition,
        [MIXPANEL.PARAMETER.RAIL_TYPE]: railData?.configType,
        [MIXPANEL.PARAMETER.DEVICE_TYPE]: MIXPANEL.VALUE.WEB,
        [MIXPANEL.PARAMETER.PACK_PRICE]: currentSubscription?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
        [MIXPANEL.PARAMETER.GAME_GENRE]: item?.genre?.[0],
        [MIXPANEL.PARAMETER.GAME_PARTNER]: item?.provider,
        [MIXPANEL.PARAMETER.GAME_RATING]: item?.gameRating,
        [MIXPANEL.PARAMETER.GAME_TITLE]: item?.title,
        [MIXPANEL.PARAMETER.FREE_GAME]: !isUserSubscribed ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO,
    }
    if (isPlayNow) {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HEROBANNER_PLAYNOW, analyticsPlayNow);
    } else {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.GAME_CLICK, mixpanelData);
    }
}

export const trackMixpanelFavouriteClick = (props) => {
    const { item } = props;
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {});
    let isUserSubscribed = checkCurrentSubscription(currentSubscription);
    let mixpanelData = {
        [MIXPANEL.PARAMETER.PAGE_NAME]: getAnalyticsSource(props?.location?.pathname, MIXPANEL),
        [MIXPANEL.PARAMETER.SOURCE]: getAnalyticsSource(props?.location?.pathname),
        [MIXPANEL.PARAMETER.RAIL_CATEGORY]: props?.sectionType,
        [MIXPANEL.PARAMETER.GAME_POSITION]: props?.contentPosition + 1,
        [MIXPANEL.PARAMETER.RAIL_TITLE]: props?.title,
        [MIXPANEL.PARAMETER.RAIL_POSITION]: props?.railPosition,
        [MIXPANEL.PARAMETER.RAIL_TYPE]: props?.configType,
        [MIXPANEL.PARAMETER.GAME_GENRE]: item?.genre?.[0],
        [MIXPANEL.PARAMETER.GAME_PARTNER]: item?.provider,
        [MIXPANEL.PARAMETER.GAME_RATING]: item?.gameRating,
        [MIXPANEL.PARAMETER.GAME_TITLE]: item?.title,
        [MIXPANEL.PARAMETER.FREE_GAME]: !isUserSubscribed ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO,
        [MIXPANEL.PARAMETER.DEVICE_TYPE]: MIXPANEL.VALUE.WEB,
        [MIXPANEL.PARAMETER.PACK_PRICE]: currentSubscription?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
    };
    if (checkIsGamezopFav(item?.id)) {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.GAME_ADD_FAVOURITE, mixpanelData);
    } else {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.GAME_REMOVE_FAVOURITE, mixpanelData);
    }
}

export const filterRefUseCase = (id, pageType) => {
    if (pageType === "searchLanguage" || pageType === "searchGenre") {
        return getSearchRefUseCaseId();
    } else {
        let state = store.getState();
        let heiarchchyData = get(state, "homeDetails.homeScreenHeirarchyData", "");
        const obj =
            !isEmpty(heiarchchyData) &&
            heiarchchyData.filter((data) => id === data.railId);
        return get(obj, "[0].refId", "");
    }
};

/**
   * @function handleMidScrollSeeAllRedirection
   * @description
   * @ anchorValue - identifier for SEO anchor tag href value result
   * This function redirect the user to see-all page from mid-scroll click.
   */
export const handleMidScrollSeeAllRedirection = (props, anchorValue = false) => {
    let { history, item } = props,
        pathname = history?.location?.pathname,
        routeUrl = pathname && pathname.split("/"),
        breadCrumbSource = pathname && routeUrl[1];

    let state = {
        railPosition: props?.railPosition,
        configType: item?.sectionSource,
        railTitle: item?.title,
        pageName: getAnalyticsSource(pathname),
        layoutType: item?.layoutType,
        sectionSource: item?.sectionSource,
        routeName: pathname,
        contentLocation: history?.location,
    };
    
    let pathUrl = getSeeAllUrl(false, item?.contentType, item?.railId, props?.item);
    if(anchorValue){
        return pathUrl
    } else {
        safeNavigation(history, {
            pathname: pathUrl,
            state,
            search: `?breadCrumbSource=${breadCrumbSource}`,
        });
    }
}

/**
   * @function midBannerRedirection
   * @description
   * This function redirect the user to different screen.
   */

export const midBannerRedirection = (item, props) => {
    let redirectionPath = getMidBannerRedirectionPath(item?.screenName, props);
    redirectionPath && safeNavigation(props?.history, redirectionPath)
}

/**
   * @function getMidBannerRedirectionPath
   * @description
   * this function is responsible to getThe pathname of diffferent screen
   */


export const getMidBannerRedirectionPath = (screenName, props , anchorValue = false) => {
    switch (screenName) {
        case SCREEN_NAME.MID_SCROLL_DETAIL_SCREEN:
            return getPathNameOnClick(props);
        case SCREEN_NAME.MID_SCROLL_GAMEZOP:
            return URL.GAMES;
        case SCREEN_NAME.MID_SCROLL_MOVIES:
            return URL.MOVIES;
        case SCREEN_NAME.MID_SCROLL_SHOWS:
            return URL.TV_Shows;
        case SCREEN_NAME.MID_SCROLL_SPORTS:
            return URL.SPORTS;
        case (SCREEN_NAME.MID_SCROLL_SEE_ALL_SCREEN):
            return handleMidScrollSeeAllRedirection(props, anchorValue);
        default:
             return anchorValue ? null : subscriptionRedirectionMidScroll() 
    }
}

/**
   * @function subscriptionRedirectionMidScroll
   * @description
   * this function is responsible to redirect the user to subcription journey.
   */


const subscriptionRedirectionMidScroll = () => {
    let state = store.getState();
    let isManagedApp = get(state.headerDetails, "isManagedApp")
    let checkSubscriptionStatus = checkCurrentSubscription(get(state.subscriptionDetails, "currentSubscription.data"))
    let path = "";
    !isManagedApp ? (path = URL.SUBSCRIPTION)
    : !checkSubscriptionStatus && isUserloggedIn() ? (path = URL.SUBSCRIPTION )
        : redirectToMangeApp(FIREBASE.VALUE.HOME_SUBSCRIBE)
    return path;
   
}

/**
 * 
 * @param {*} item 
 * @param {*} sectionSource 
 * @returns checks if the content being painted on search page is of binge live or darshan content then pick sectionb source of content other 
 * than that of the parent section source
 */
export const liveSectionSourceOnSearch = (item, sectionSource) => {
    let sectionSourceToCheck = sectionSource;
    if (sectionSource === SECTION_SOURCE.SEARCH && [SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(item?.sectionSource)) {
        sectionSourceToCheck = item?.sectionSource;
    }

    return sectionSourceToCheck;
}

export const redirectToChannelPage = (props, isAnchor = false) => {
    const {
        history,
        item,
        railItem
    } = props;
    let pathname = history?.location?.pathname,
        routeUrl = pathname && pathname.split("/");
    const refUseCase = filterRefUseCase(railItem?.id);

    let state = {
        railPosition: props?.railPosition,
        configType: railItem.configType,
        railTitle: item?.title,
        pageName: getAnalyticsSource(pathname),
        layoutType: item?.layoutType,
        sectionSource: item?.sectionSource,
        routeName: pathname,
        contentLocation: history?.location,
        railItem: railItem,
        item: item,
        refUseCase: refUseCase
    };
    let redirectUrl = `/${URL.CHANNEL}/${getFormattedURLValue(item?.title)}/${item?.id}`;

    if (isAnchor) {
        return redirectUrl;
    }

    triggerChannelRailEvents(state);
    safeNavigation(history, {
        pathname: redirectUrl,
        state: state,
    });

}

export const checkBestOfAppRail = (railItem) => {
    if (isEmpty(railItem)) return
    return railItem?.sectionSource === SECTION_SOURCE.RECOMMENDATION && (railItem?.placeHolder?.includes(BEST_OF_APPS.DEFAULT)) || (railItem?.placeHolder?.includes(BEST_OF_APPS.PARTNER));
};

export const getProviderForUrl = (provider) => {
    if (provider?.toLowerCase() === PROVIDER_NAME.HOTSTAR) {
        return PROVIDER_NAME.JIO_HOTSTAR;
    } else if (provider?.toLowerCase() === PROVIDER_NAME.JIO_HOTSTAR) {
        return PROVIDER_NAME.HOTSTAR;
    } else {
        return provider;
    };
}