import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

function OrganizationSchema() {
  return (
    <Fragment>
        <Helmet>
          <script type="application/ld+json">
          {`
              {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Tata Play Binge",
              "url": "https://www.tataplaybinge.com/",
              "Image": "https://www.tataplaybinge.com/df950e1ecde65bc295d781ef8a591ecc.svg",
              "Logo": {
                       "@type": "ImageObject",
                       "url": "https://www.tataplaybinge.com/df950e1ecde65bc295d781ef8a591ecc.svg",
                       "width":80, 
                       "height":19
                     },
              "description": "Tata Play Binge is your one-stop destination for all the entertainment from 30+ OTT apps. Enjoy the latest movies, web series, originals, LIVE cricket and more from your favourite OTT apps such as Prime Video, Zee5, Apple TV+, Sony LIV, Aha, Lionsgate Play and more.",
              "telephone": "18002086633",
              "sameAs": [
              "https://www.facebook.com/TataPlayBinge",
              "https://www.instagram.com/tataplaybingeofficial/",
              "https://twitter.com/TataPlayBinge",
              "https://www.youtube.com/@TataPlayBinge",
              "https://play.google.com/store/apps/details?id=com.tatasky.binge",
              "https://apps.apple.com/us/app/tata-play-binge/id1555688122"
              ] 
            }
         `}
          </script>
          </Helmet>
    </Fragment>
  )
}

export default React.memo(OrganizationSchema)